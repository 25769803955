import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import './index.css';
import App from './App';
import './assets/css/app.css';
import './assets/css/base.css';
import './assets/css/highcontrast.css';
import './assets/css/q2-theme.css';
// import './assets/css/q2.css';
// import './assets/css/tecton.css';
import './assets/css/theme.css';
import Code from './Code';
import Email from './Email';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/authorizecode" element={<Code />} />
      <Route path="/email" element={<Email />} />
    </Routes>    
    </BrowserRouter>
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
